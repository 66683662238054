import { useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import LogRocket from "logrocket"
import setupLogRocket from "logrocket-react"
// CSS
import "./App.scss"
// Components
import Header from "./components/Header"
import Home from "./components/Home"
import Portfolio from "./components/Portfolio"
import ProjectPage from "./components/ProjectPage"
import Contact from "./components/Contact"
import Footer from "./components/Footer"
import NotFound from "./components/NotFound"
// Data
import projects from "./data/projects"

function App() {
  let categories = ["All"]

  const updateMargin = () => {
    document.getElementById("container").style.marginTop =
      document.getElementById("header").offsetHeight * 1.1 + "px"
  }

  const createCategories = () => {
    projects.forEach((item) => {
      !categories.includes(item.category) && categories.push(item.category)
    })
  }

  useEffect(() => {
    createCategories()
    updateMargin()
    window.addEventListener("resize", updateMargin)
    LogRocket.init("0383bv/dawid-turek")
    setupLogRocket(LogRocket)
  }, [])

  return (
    <Router>
      <Header />
      <div className="container" id="container">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/portfolio">
            <Portfolio proj={projects} cats={categories} />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/project">
            <ProjectPage />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </Router>
  )
}

export default App
