import "./styles/Header.scss"
import { Link, useLocation } from "react-router-dom"
import pages from "../data/pages"

const Header = () => {
  const location = useLocation().pathname

  return (
    <header className="header" id="header">
      <div className="wrapper">
        <ul>
          <li key={0}>
            <Link to="/" className={location === "/" ? "green" : ""}>
              Dawid Turek
            </Link>
          </li>

          {pages.map((page) => {
            return (
              <li key={page.id}>
                <Link
                  to={page.url}
                  className={location === page.url ? "green" : ""}
                >
                  {page.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </header>
  )
}

export default Header
