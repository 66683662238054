const pages = [
  {
    id: 1,
    title: "Portfolio",
    url: "/portfolio",
  },
  {
    id: 2,
    title: "Contact",
    url: "/contact",
  },
  // {
  //   id: 3,
  //   title: "CV",
  //   url: "/cv",
  // },
]

export default pages
