import "./styles/Footer.scss"
import { FaReact, FaSass } from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="footer">
      <FaReact className="icon react-icon" title="Made with React." />
      <FaSass className="icon sass-icon" title="And Sass too!" />
    </footer>
  )
}

export default Footer
