import "./styles/Home.scss"

const Home = () => {
  return (
    <main className="home">
      <div className="about">
        <div className="content">
          <p>const about = &#123;</p>
          <p>
            &emsp;&emsp;"name"<span>: </span>"Dawid Turek"
          </p>
          <p>
            &emsp;&emsp;"age"<span>: </span>22
          </p>
          <p>
            &emsp;&emsp;"city"<span>: </span>"Liverpool"
          </p>
          <p>
            &emsp;&emsp;"info"<span>: </span>"Welcome to my portfolio! Use the
            links above to see more about me."
          </p>

          <p>&#125;</p>
        </div>
      </div>
    </main>
  )
}

export default Home
