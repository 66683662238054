const NotFound = () => {
  return (
    <main className="not-found">
      <h1>404</h1>
      <p>Sorry, I haven't made that page yet!</p>
    </main>
  )
}

export default NotFound
