import { useEffect, useState } from "react"
import { init, sendForm } from "emailjs-com"
import { FaInstagram } from "react-icons/fa"
import { AiOutlineLoading } from "react-icons/ai"

import "./styles/Contact.scss"

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault()
    setIsLoading(true)
    setEmailSent(false)

    sendForm("service_wjv7zix", "template_oopelys", e.target).then(
      (response) => {
        setIsLoading(false)
        setEmailSent(true)
      },
      (error) => {
        setIsLoading(false)
        setEmailSent(true)
        document.getElementById("emailInfo").innerHTML =
          "The e-mail could not be sent. Please try again later or e-mail me directly."
      }
    )
  }

  const changeHelperText = (e) => {
    const len = e.target.value.length
    const min = e.target.minLength
    const text = document.getElementById("helperText")
    len < min
      ? (text.innerHTML = `(${min - len} ${
          min - len > 1 ? "characters" : "character"
        } required)`)
      : (text.innerHTML = "")
  }

  useEffect(() => {
    init("user_DoYc1TMuovJxLXFHrksvJ")
  }, [])

  return (
    <main className="contact">
      <h1>Contact Me</h1>
      <div className="wrapper">
        <section className="left">
          <div>
            <h2>My details</h2>
          </div>
          <div>
            <h4>E-mail</h4>
            <p>
              <a href="mailto:dawid@dawidturek.com">dawid@dawidturek.com</a>
            </p>
          </div>
          <div>
            <h4>Mobile</h4>
            <p>
              <a href="tel:+447427398655">(+44) 7427398655</a>
            </p>
          </div>
          <div>
            <h4>Website</h4>
            <p>
              <a href="https://www.dawidturek.com/">
                https://www.dawidturek.com/
              </a>
            </p>
          </div>
          <div className="social-media">
            <h4>Social Media</h4>
            <span>
              <a
                href="https://www.instagram.com/dawidtur/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram className="social-icon social-instagram" />
              </a>
            </span>
          </div>
        </section>
        <section className="right">
          <h2>Contact Form</h2>
          {!emailSent ? (
            !isLoading ? (
              <form
                onSubmit={(e) => {
                  sendEmail(e)
                }}
                id="contact-form"
              >
                <fieldset>
                  <label htmlFor="from_name">Your name</label>
                  <input
                    type="text"
                    name="from_name"
                    id="from_name"
                    placeholder="e.g. John Doe"
                    required
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="from_email">Your e-mail</label>
                  <input
                    type="email"
                    name="from_email"
                    id="from_email"
                    placeholder="e.g. john.doe@gmail.com"
                    required
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="message">
                    Message{" "}
                    <span id="helperText">(20 characters required)</span>
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows="5"
                    minLength={20}
                    required
                    onChange={(e) => changeHelperText(e)}
                  ></textarea>
                </fieldset>
                <input type="submit" value="Submit" />
              </form>
            ) : (
              <AiOutlineLoading className="loading-icon-white" />
            )
          ) : (
            <h4 id="emailInfo">
              Thank you for sending a message! I will reply as soon as possible.
            </h4>
          )}
        </section>
      </div>
    </main>
  )
}

export default Contact
