import { Link } from "react-router-dom"

const Project = ({ project }) => {
  const { id, title, thumb, tags } = project
  return (
    <Link to={`project?id=${id}`} tabIndex={0} className="project-link">
      <div className="project" tabIndex={-1}>
        <figure>
          <div className="img-wrapper">
            <img src={thumb} alt={title} />
          </div>
          <figcaption>
            <p className="title">{title}</p>
            <p className="tags">{tags.join(", ")}</p>
          </figcaption>
        </figure>
      </div>
    </Link>
  )
}

export default Project
