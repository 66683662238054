import { useState, useEffect } from "react"
import "./styles/ProjectPage.scss"
import projects from "../data/projects"
import QueryString from "query-string"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { GoChevronLeft } from "react-icons/go"
import Slider from "./Slider"

const ProjectPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [project, setProject] = useState({})

  const { search } = useLocation()
  const info = QueryString.parse(search)

  const fetchProject = async (id) => {
    const currProject = projects.filter((project) => {
      return project.id === parseFloat(info.id)
    })
    setProject(...currProject)
  }

  useEffect(() => {
    fetchProject(info.id).then(() => {
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <h1>Loading...</h1>
  } else {
    return (
      <main className="project-page">
        <div className="controls">
          <Link to="/portfolio">
            <GoChevronLeft />
            Go Back
          </Link>
        </div>
        <div className="left">
          <p className="category" key="category">
            {project.category}
          </p>
          <div className="image-slider-wrapper">
            <Slider images={project.images} />
          </div>
        </div>
        <div className="right">
          <h1 className="title">{project.title}</h1>
          <div className="tags" key="tags">
            <span>Tags:</span>
            {project.tags.map((tag) => {
              return (
                <p className="tag" key={tag}>
                  {tag}
                </p>
              )
            })}
          </div>
          {project.description !== "" && (
            <p className="description" key="description">
              <span>Description: </span>
              {project.description}
            </p>
          )}
          <ul
            className="content"
            dangerouslySetInnerHTML={{ __html: project.content }}
          ></ul>
          <p className="url" key="url">
            <span>Link: </span>
            {project.url !== "" ? (
              <a href={project.url} target="_blank" rel="noreferrer">
                {project.url}
              </a>
            ) : (
              <span>Not available yet!</span>
            )}
          </p>
        </div>
      </main>
    )
  }
}

export default ProjectPage
