const projects = [
  {
    id: 1,
    title: "Kordeusz.pl",
    thumb: "/img/portfolio/kordeusz/thumb.png",
    category: "Wordpress",
    tags: ["Divi", "Responsive", "Wordpress"],
    images: [
      "../img/portfolio/kordeusz/1.jpg",
      "../img/portfolio/kordeusz/2.jpg",
      "../img/portfolio/kordeusz/3.jpg",
      "../img/portfolio/kordeusz/4.jpg",
    ],
    description: "",
    content:
      "<li>Theme development using DIVI</li><li>Responsive website layout</li><li>Setting up e-mail system</li><li>User-friendly content and animations</li><li>Multiple-page design</li><li>Social media integration</li>",
    url: "https://kordeusz.pl/",
    hidden: false,
  },
  {
    id: 2,
    title: "Studio Przelot",
    thumb: "/img/portfolio/przelot/thumb.jpg",
    category: "Wordpress",
    tags: ["Divi", "Responsive", "Wordpress"],
    images: [
      "/img/portfolio/przelot/1.jpg",
      "/img/portfolio/przelot/2.jpg",
      "/img/portfolio/przelot/3.jpg",
    ],
    description: "",
    content:
      "<li>Theme development using DIVI</li><li>Responsive website layout</li><li>User-friendly content and animations</li><li>Multiple-page design</li><li>Google API integration (Maps)</li>",
    url: "https://studioprzelot.pl/",
    hidden: false,
  },
  {
    id: 3,
    title: "CLT&CD",
    thumb: "/img/portfolio/cltcd/thumb.jpg",
    category: "Wordpress",
    tags: ["Divi", "Responsive", "Wordpress", "Languages"],
    images: [
      "/img/portfolio/cltcd/1.jpg",
      "/img/portfolio/cltcd/2.jpg",
      "/img/portfolio/cltcd/3.jpg",
    ],
    description: "",
    content:
      "<li>Theme development using DIVI</li><li>Language integeration using WPML</li><li>Responsive website layout</li><li>Setting up e-mail system</li><li>User-friendly content and animations</li><li>Multiple-page design</li><li>Social media integration</li>",
    url: "https://www.cltcd.com/",
    hidden: false,
  },
  {
    id: 4,
    title: "COVID-19 Tracker App",
    thumb: "/img/portfolio/covid19/thumb.jpg",
    category: "API",
    tags: ["API", "Responsive", "Bootstrap", "Grid"],
    images: ["/img/portfolio/covid19/1.jpg", "/img/portfolio/covid19/2.jpg"],
    description:
      "This app allows the user to input a country and see the confirmed cases, recoveries, critical conditions and deaths of the coronavirus.",
    content:
      "<li>Fetching of data from API</li><li>Styled using Bootstrap framework</li>",
    url: "https://www.dawidturek.com/projects/COVID/",
    hidden: false,
  },
  {
    id: 5,
    title: "Weather on Mars App",
    thumb: "/img/portfolio/nasa/thumb.jpg",
    category: "API",
    tags: ["API", "Responsive", "Bootstrap", "Grid"],
    images: ["/img/portfolio/nasa/1.jpg", "/img/portfolio/nasa/2.jpg"],
    description:
      "The Weather on Mars app fetches data from the NASA InSight API to show the weather on Mars over the past 5 days (or sols).",
    content:
      "<li>Fetching data from NASA InSight API</li><li>Styled using Bootstrap framework</li><li>Slider to change between temperature units</li>",
    url: "https://www.dawidturek.com/projects/NASA/",
    hidden: false,
  },
  {
    id: 6,
    title: "Word Generator App",
    thumb: "/img/portfolio/randomword/thumb.jpg",
    category: "API",
    tags: ["API", "Bootstrap"],
    images: ["/img/portfolio/randomword/1.jpg"],
    description:
      "A simple app that shows the user a random word, fetched from an API and its definition.",
    content:
      "<li>Fetches data from a random word API</li><li>Styled using Bootstrap framework</li>",
    url: "https://www.dawidturek.com/projects/RandomWord/",
    hidden: false,
  },
  {
    id: 7,
    title: "Todo App",
    thumb: "/img/portfolio/todo/thumb.jpg",
    category: "React",
    tags: ["React", "Custom CSS", "Data Storage"],
    images: ["/img/portfolio/todo/1.jpg", "/img/portfolio/todo/2.jpg"],
    description:
      "A Todo app that allows the user to add, favourite and delete tasks and saves them in their local storage.",
    content:
      "<li>Created with React</li><li>Allows the user to add, favourite or delete tasks</li><li>Tasks are stored in local storage so user can come back to them later</li>",
    url: "https://dawidturek.com/projects/react/todo/",
    hidden: false,
  },
  {
    id: 8,
    title: "Reddit App",
    thumb: "/img/portfolio/reddit/thumb.jpg",
    category: "React",
    tags: ["React", "Custom CSS", "API"],
    images: ["/img/portfolio/reddit/1.jpg", "/img/portfolio/reddit/2.jpg"],
    description:
      "An app that fetches a user's Reddit posts or comments using the Pushshift API.",
    content:
      "<li>Created with React</li><li>Shows a Reddit user's posts or comments (dependant on user's choice)</li>",
    url: "https://www.dawidturek.com/projects/react/reddit/",
    hidden: false,
  },
  {
    id: 9,
    title: "Measurements App",
    thumb: "/img/portfolio/measurements/thumb.jpg",
    category: "React",
    tags: ["React", "Bootstrap"],
    images: [
      "/img/portfolio/measurements/1.jpg",
      "/img/portfolio/measurements/2.jpg",
    ],
    description:
      "This app is a simple measurements conversion app that allows the user to choose their units and copy the result.",
    content:
      "<li>Created with React</li><li>User has option to pick the units they want</li><li>Result is shown and is able to be copied with a button</li>",
    url: "https://www.dawidturek.com/projects/react/measurements/",
    hidden: false,
  },
  {
    id: 10,
    title: "CSS Blend Modes",
    thumb: "/img/portfolio/blendmodes/thumb.jpg",
    category: "CSS",
    tags: ["CSS", "Sass", "Responsive", "Experiment"],
    images: [
      "/img/portfolio/blendmodes/1.jpg",
      "/img/portfolio/blendmodes/2.jpg",
    ],
    description: "An app created in order to experiment with CSS blend modes.",
    content:
      "<li>Created with HTML5, Sass, JS, jQuery and jQuery UI</li><li>User can choose from different blend modes and colours</li><li>Draggable objects using jQuery UI</li>",
    url: "https://www.dawidturek.com/projects/BlendModes/",
    hidden: false,
  },
  {
    id: 11,
    title: "CSS Buttons",
    thumb: "/img/portfolio/buttons/thumb.jpg",
    category: "CSS",
    tags: ["CSS", "Responsive", "Experiment"],
    images: ["/img/portfolio/buttons/1.jpg", "/img/portfolio/buttons/2.jpg"],
    description: "A couple ways of styling buttons using mainly CSS.",
    content:
      "<li>All buttons have hover and focus effects, making them more accessible</li>",
    url: "http://dawidturek.com/projects/AdvancedButtons.html",
    hidden: false,
  },
  {
    id: 12,
    title: "Online Furniture Shop (WIP)",
    thumb: "/img/portfolio/shopping/thumb.jpg",
    category: "React",
    tags: ["React", "Custom CSS", "WIP"],
    images: ["/img/portfolio/shopping/1.jpg"],
    description:
      "An online store built using React, currently work in progress.",
    content:
      "<li>Modern styling using new CSS features, including flex and grid</li><li>Shopping cart items stored in local storage</li><li>Products are fetched from external file</li><li>Product sorting and filtering</li>",
    url: "",
    hidden: false,
  },
  {
    id: 13,
    title: "Clicker Game",
    thumb: "/img/portfolio/clicker/thumb.jpg",
    category: "React",
    tags: ["React", "Sass", "Game"],
    images: ["/img/portfolio/clicker/1.jpg", "/img/portfolio/clicker/2.jpg"],
    description: "A clicker game made using React and Sass for practice.",
    content:
      "<li>Modern styling using new CSS features, including flex and grid</li><li>Uses React hooks in order to store and update data on the screen</li><li>Progress is stored in local storage</li><li>Styled using Sass</li>",
    url: "https://www.dawidturek.com/projects/react/clicker/",
    hidden: false,
  },
]

export default projects
