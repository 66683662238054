import { useEffect, useState } from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

import "./styles/Slider.scss"

const Slider = ({ images }) => {
  const [index, setIndex] = useState(0)

  const changePicture = (direction) => {
    switch (direction) {
      case "next":
        index !== images.length - 1 ? setIndex(index + 1) : setIndex(0)
        break
      case "previous":
        index !== 0 ? setIndex(index - 1) : setIndex(images.length - 1)
        break
      default:
        setIndex(index)
    }
  }

  return (
    <div className="image-slider">
      {/* {images.map((image) => {
        return (
          <a
            href={image}
            target="_blank"
            rel="noreferrer"
            key={Math.floor(Math.random() * 10000)}
          >
            <img src={image} alt="" />
          </a>
        )
      })} */}
      <p className="current-slide">
        {index + 1} / {images.length}
      </p>
      <img src={images[index]} alt="" />
      {images.length > 1 && (
        <>
          <button
            className="btn-prev"
            onClick={() => changePicture("previous")}
          >
            <FaChevronLeft />
          </button>
          <button className="btn-next" onClick={() => changePicture("next")}>
            <FaChevronRight />
          </button>
        </>
      )}
    </div>
  )
}

export default Slider
