import { useState, useEffect } from "react"
import "./styles/Portfolio.scss"
import Project from "./Project"
import { AiOutlineLoading } from "react-icons/ai"

const Portfolio = ({ proj, cats }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [projects, setProjects] = useState([])
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState("All")

  const changeCategory = (e) => {
    setCurrentCategory(e.target.innerHTML)
  }

  useEffect(() => {
    if (currentCategory !== "All") {
      const newProjects = projects.filter((project) => {
        return project.category === currentCategory
      })
      setProjects(newProjects)
    }
  }, [currentCategory])

  useEffect(() => {
    setCategories(cats)
    setProjects(proj)
    setIsLoading(false)
  }, [])

  if (!isLoading) {
    return (
      <main className="portfolio">
        <h1 className="title">
          Portfolio{" "}
          {currentCategory !== "All" && (
            <>
              <span key={1}> / </span> {currentCategory}{" "}
              <span key={2}> / </span>
            </>
          )}
        </h1>
        <div className="categories">
          <p>Category:</p>
          {categories.map((cat) => {
            return (
              <button
                key={cat}
                className={`category ${
                  cat === currentCategory && "category-active"
                }`}
                onClick={(e) => {
                  setProjects(proj)
                  changeCategory(e)
                }}
              >
                {cat}
              </button>
            )
          })}
        </div>
        <div className="projects">
          {projects.map((project) => {
            if (!project.hidden) {
              return <Project key={project.id} project={project} />
            }
          })}
        </div>
      </main>
    )
  } else {
    return (
      <main className="loading">
        <AiOutlineLoading className="loading-icon" />
      </main>
    )
  }
}

export default Portfolio
